import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";
import Ajustes from "./Ajustes";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      {/* <Route path={`${match.url}sample`} component={asyncComponent(() => import('./SamplePage'))}/> */}
      
      <Route path={`${match.url}Dashboard`} component={asyncComponent(() => import('./Dashboard'))}/>
      <Route path={`${match.url}Analisis`} component={asyncComponent(() => import('./Analisis'))}/>
      {/* <Route path={`${match.url}Reporte`} component={asyncComponent(() => import('./Reporte'))}/> */}
      <Route path={`${match.url}Ajustes`} component={asyncComponent(() => import('./Ajustes'))}/>
    </Switch>
  </div>
);

export default App;
