import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Ajustes =({match})=>(
    <Switch>
        <Route path={`${match.url}/maquinaria`} component={asyncComponent(() => import('./Maquinarias/index'))}/>
        <Route path={`${match.url}/operarios`} component={asyncComponent(() => import('./Operarios/index'))}/>
        <Route path={`${match.url}/usuarios`} component={asyncComponent(() => import('./Usuarios/index'))}/>
        
    </Switch>
);

export default Ajustes;