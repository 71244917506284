import React from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
//import IntlMessages from "../../util/IntlMessages";
import {useSelector} from "react-redux";

const SubMenu = Menu.SubMenu;
//const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = ({sidebarCollapsed, setSidebarCollapsed}) => {
  const {navStyle, themeType} = useSelector(({settings}) => settings);
  const pathname = useSelector(({common}) => common.pathname);

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  return (
    <>
      <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed}/>
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <UserProfile/>
          <AppsNavigation/>
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">

              {/* <MenuItemGroup key="ajustes" title="Ajustes"> */}
                

              {/* </MenuItemGroup>  */}

            {/* <Menu.Item key="sample">
              <Link to="/sample"><i className="icon icon-widgets"/>
                <span><IntlMessages id="sidebar.samplePage"/></span>
              </Link>
            </Menu.Item> */}

            <Menu.Item key="dashboard">
              <Link to="/dashboard"><i className="icon icon-dasbhoard"/>
                <span>Dashboard</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="analisis">
              <Link to="/analisis"><i className="icon icon-data-display"/>
                <span>Análisis</span>
              </Link>
            </Menu.Item>
            {/* <Menu.Item key="reporte">
              <Link to="/reporte"><i className="icon icon-data-display"/>
                <span>Reporte</span>
              </Link>
            </Menu.Item> */}
            <SubMenu key="ajustes" title={<span> <i className="icon icon-extra-components"/>
                         <span>Ajustes</span></span>}>
                  <Menu.Item key="/ajustes/operarios">
                    <Link to="/ajustes/operarios">
                      <i class="icon icon-wall"/>
                      <span>Operarios</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/ajustes/maquinaria">
                    <Link to="/ajustes/maquinaria">
                      <i class="icon icon-orders"/>
                      <span>Maquinaria</span>                    
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/">
                    <Link to="/">
                      <i class="icon icon-orders"/>
                      <span>Usuarios</span>                    
                    </Link>
                  </Menu.Item>
                </SubMenu>

          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);

